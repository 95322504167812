.blog {
	.banner-blog {
		background-image: url(RESOURCE/img/blog-banner.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 90%;
		width: 100%;
		height: 400px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			opacity: .7;
			left: 0;
			background-color: #2e4852;
			width: 100%;
			height: 100%;
		}

		h1 {
			color: var(--white);
			position: relative;
			z-index: 2;
		}
	}

	.blog-entry {
		color: #333;
		display: block;
		text-decoration: none;
		margin-top: 20px;
		padding-top: 20px;
		border-top: 1px dotted #7c949f;

		.blog-header {
			margin-bottom: 10px;
		}

		h2 {
			font-size: 24px;
			margin-top: 0;
		}
	}

}


.entries {
	padding-bottom: 60px;
}

.blogentry {
	&.container {
		padding-bottom: 60px;
		padding-top: 90px;
	}

	.tag-box {
		padding: 0;
		margin: 0;

		li {
			display: inline-block;
			vertical-align: middle;
			margin: 0 5px 5px 0;

			a {
				background-color: #ddd;
				padding: 5px;
				display: block;

			}
		}
	}
}