@import "font-awesome.css";

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Bold.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Bold.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Regular.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Regular.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/RESOURCE/fonts/SourceSansPro-SemiBold.eot');
	src: url('RESOURCE/fonts/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Font Awesome 5 Free';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

.far {
	font-family: 'Font Awesome 5 Free';
	font-weight: 400;
}

@font-face {
	font-family: 'Font Awesome 5 Free';
	font-style: normal;
	font-weight: 900;
	font-display: block;
	src: url("RESOURCE/fonts/fa-solid-900.eot");
	src: url("RESOURCE/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-solid-900.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-solid-900.woff") format("woff"),
		url("RESOURCE/fonts/fa-solid-900.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-solid-900.svg#fontawesome") format("svg");
}

.fa,
.fas {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
}

@font-face {
	font-family: 'Font Awesome 5 Brands';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-brands-400.eot");
	src: url("RESOURCE/fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-brands-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-brands-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-brands-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-brands-400.svg#fontawesome") format("svg");
}

.fab {
	font-family: 'Font Awesome 5 Brands';
	font-weight: 400;
}


@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}


@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.flex {
	@mixin flex;
}


@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}


.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {
	label {
		font-weight: normal;
	}

	label.lrequired {
		font-weight: 700;
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.asd__day--tada {
	background-color: red;
}

.datepicker-trigger {
	--cal_not_available: #cd7898;

	.asd__day--not-available {
		background: #42779e !important;
		opacity: 1 !important;
		color: #fff !important;
	}

	.asd__day--arrival {
		background: linear-gradient(90deg, white 70%, #42779e 80%) !important;
		opacity: 1 !important;
	}

	.asd__day--departure {
		background: linear-gradient(90deg, #42779e 20%, white 30%) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, #e4e7e7 70%, #42779e 80%) !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, #42779e 20%, #e4e7e7 30%) !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background: rgb(102, 226, 218) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: rgb(103, 246, 238) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		color: rgba(86, 90, 92, 0.5) !important;
	}

	.asd__day--selected {
		background: #7caed3 !important;
		opacity: 1 !important;
		border: 1px double #fff !important;
	}


}

.asd__day.asd__day--enabled.asd__day--in-range,
.asd__day.asd__day--enabled.asd__day--hovered {
	background: #c5e4fb !important;
	border: 1px double rgb(255 255 255) !important;
	color: #565a5c !important;
}

/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}


.min-row {
	margin: 0 -10px;

	.col-md-7,
	.col-md-5,
	.col-md-6,
	.col-md-8,
	.col-md-4 {
		padding: 0 10px;
	}
}

.btn {
	border-radius: 0;
	font-size: 16px;
	padding: 8px 16px;

	&.btn-primary,
	&.btn-go {
		background-color: var(--primary);
		color: var(--white);
		border-color: var(--primary);

		&:hover {
			background-color: var(--black);
			border-color: var(--black);
		}
	}

	&.btn-sm {
		font-size: 14px;
		padding: 6px 12px;
	}

	&.btn-secondary {
		background-color: var(--black);
		color: var(--white);
		border-color: var(--black);

		&:hover {
			background-color: var(--primary);
			border-color: var(--primary);
		}
	}

	&.btn-lg {
		height: 46px;
		font-size: 18px;
		padding: 10px 16px;
	}
}


/* basic css */
.container {
	max-width: 100%;
}

.checkbox {
	margin: 0;

	a {
		color: var(--primary);
		position: relative;
		top: 1px;
	}
}

.doc-box {
	text-align: left;
}

.min-row {
	margin: 0 -10px;

	.col-md-7,
	.col-md-5,
	.col-md-6,
	.col-md-8,
	.col-md-4,
	.col-md-9,
	.col-md-3,
	.col-sm-7,
	.col-sm-5 {
		padding: 0 10px;
	}
}

.ptop-25 {
	padding-top: 25px;
}

.txt-prim {
	color: var(--primary);
}

.mt-0 {
	margin-top: 0 !important;
}


.mb-0 {
	margin-bottom: 0 !important;
}

.mt-20 {
	margin-top: 20px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.pb-20 {
	padding-bottom: 20px;
}

.pad60 {
	padding: 60px 0;
}

h1 {
	font-family: var(--d-font);
	font-size: 40px;
	font-weight: 700;
}

h2 {
	font-family: var(--d-font);
	font-size: 34px;
}

.bdr-top {
	border-top: 2px solid var(--primary);
}

h1,
h2,
h3,
h4 {
	margin-top: 0;
}


.text-md-right {
	@media screen (min-width:993px) {
		text-align: right;
	}
}

a:focus,
.btn:focus {
	outline: 0;
	text-decoration: none !important;
}

.d-block {
	display: block;
}

.list-block {
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		display: block;
		padding: 3px 0;

		a {
			color: var(--black);
		}
	}
}

.list-icon {
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		display: block;
		padding: 3px 0;
		padding-left: 25px;
		position: relative;

		i {
			color: var(--primary);
			position: absolute;
			left: 0;
			top: 7px;
		}

		a {
			color: var(--black);
		}
	}
}

body {
	color: var(--black);
	font-family: var(--d-font);
	font-size: 14px;
}

.form-group {
	.control-label {
		text-align: left;
	}
}

.pt-20 {
	padding-top: 20px;
}

.checkbox-success input[type=checkbox]:checked+label:before {
	background-color: var(--primary) !important;
	border-color: var(--primary) !important;
}

.bg-prim {
	background-color: var(--primary);
	color: var(--white);
}

.form-control {
	border-radius: 0;
	text-align: left;
}

.big-para {
	p {
		font-size: 20px;
		line-height: 1.5;
	}
}

.asd__wrapper--full-screen {
	z-index: 50 !important;
}

.text-prim {
	color: var(--primary);
}

.my-search-token {
	border-radius: 0 !Important;
}

.breadcrumb {
	background-color: transparent;
	padding: 35px 0;
	margin-bottom: 0;

	.breadcrumb-item {
		padding-left: 8px;
		text-transform: uppercase;

		&:first-child {
			padding-left: 0;
		}

		a {
			font-size: 14px;
			text-transform: uppercase;
			color: var(--black4);

			i {
				margin-left: 8px;
			}

			&:hover {
				color: var(--primary);
			}
		}

		&.active {
			span {
				color: var(--primary);
				font-weight: bold;
			}
		}
	}
}

.list-unstyled {
	li {
		i {
			color: var(--secondary);
			margin-right: 10px;
		}
	}
}

.bold {
	font-weight: 600;
}

.pad30 {
	padding: 30px 0;
}

.form-control {
	background-color: var(--white) !important;
	padding: 8px 12px;
	font-size: 16px;
	margin-bottom: 10px;
	box-shadow: inherit;
	height: auto;
}

button:focus {
	outline: 0 !important;
}

body {
	min-height: 100vh;
	min-height: -webkit-fill-available;
}

html {
	height: -webkit-fill-available;
}

.three-units {

	.col-md-4,
	.col-sm-4 {
		display: none;

		&:first-child {
			display: block !important;
		}

		&:nth-child(2),
		&:nth-child(3) {
			display: block !important;
		}
	}
}

.season-price {
	margin-top: 110px;
}

.checkbox input[type=checkbox]:checked+label:after {
	font-family: "Font Awesome 5 Free" !important;
	font-weight: 600 !important;
}

.fa.fa-heart-o {
	font-family: "Font Awesome 5 Free";
	font-weight: 400;
}

.fa.fa-heart-o:before {
	content: "\f004";
}

.fa.fa-envelope-o {
	font-weight: 400;
}

.fa.fa-square-o {
	font-family: "Font Awesome 5 Free";
	font-weight: 400;
}

.fa.fa-square-o:before {
	content: "\f0c8";
}