.indexpage {
	position: relative;

	.unit-list-section {
		.row {
			display: flex;
			flex-wrap: wrap;

		}

		.owl-item {
			position: relative;
			border-left: 5px solid var(--white);
			border-right: 5px solid var(--white);

			&:before {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(255, 255, 255, 0.6);
				content: ' ';
				transition: all 0.5s ease;
				z-index: 1;
			}

			&:hover {
				&:before {
					background: rgba(255, 255, 255, 0.8);
				}
			}

			&.active {
				&:before {
					background-color: rgba(255, 255, 255, 0);
				}
			}
		}

		.owl-nav {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			max-width: 1380px;
			margin: 0 auto;
			transform: translate(-50%, 0);

			.owl-prev,
			.owl-next {
				background: #ffffff;
				font-size: 24px;
				line-height: 40px;
				width: 40px;
				height: 40px;
				text-align: center;
				position: absolute;
				opacity: 0.5;

				&:hover {
					opacity: 1;
				}
			}

			.owl-prev {
				left: 15px;
			}

			.owl-next {
				right: 15px;
			}

			@media (max-width:992px) {
				/*display: none;*/
			}


			button {
				width: 60px;
				height: 60px;
				background-color: var(--black2) !important;
				position: absolute;
				left: 50px;
				font-size: 34px;

				span {
					display: inline-block;
					font-style: normal;
					font-variant: normal;
					text-rendering: auto;
					line-height: 1;
					color: transparent;

					&:before {
						color: var(--white);
						font-family: fontawesome;
						position: relative;
						left: 5px;
						top: 0px;
						font-size: 24px;
					}
				}

				&:hover {
					background-color: var(--color-primary) !important;
				}

				&.owl-next {
					position: absolute;
					left: initial;

					span {

						&:before {
							content: "\f054";
							left: 5px;
						}
					}
				}

				&.owl-prev {
					span {

						&:before {
							content: "\f053";

						}
					}
				}
			}
		}

		.item {
			overflow: hidden;
			width: 100%;
			padding-bottom: 56.25%;

			@media (max-width:992px) {
				height: 250px;
			}


			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
			}
		}
	}

	&:after {
		content: "";
		background-image: url(https://r.v-office.com/preview/v64/1593673912000/img/meerjungfrau2.png);
		background-repeat: no-repeat;
		background-position: bottom;
		position: absolute;
		bottom: -300px;
		width: 480px;
		height: 100%;
		z-index: -1;
		right: 0;
		background-size: 470px;
		opacity: 0.4;
	}

	.bg-prim {
		background-color: rgba(124, 174, 211, .8);
	}

	.unit-box {
		.unit-price {
			display: inline-block;
			text-align: left;
			position: absolute;
			background: rgb(124 174 211 / 0.8);
			padding: 5px 10px;
			top: -35px;
			right: 0;

			span {
				font-size: 16px;
				color: #fff;

				strong {
					font-size: 18px;
					font-weight: 700;
					color: #fff;
					color: #fff;
				}
			}
		}

		.content-section {
			h1 {
				color: var(--primary);
				margin: 0 0 30px 0;

			}
		}

		.img-col {

			.img-content {

				.full-img {
					width: 100%;
					height: auto;
				}


				.fav-icon {
					position: absolute;
					cursor: pointer;
					right: 15px;
					top: 5px;
					color: #fff;
					margin: 3px;
					font-size: 24px;
					width: 35px;
					text-align: center;
				}


			}


		}

		.info-col {
			position: relative;
			padding: 20px;
			background-color: white;
			box-shadow: 0 1px 3px rgba(0, 0, 0, .1);

			h4 {
				color: var(--primary);
				font-size: 22px;
				font-weight: 600;

				a {
					color: var(--primary) !important;
					text-decoration: none;
				}
			}

			h6 {
				font-size: 16px;
			}

			.stars {
				position: absolute;
				right: 0;
				bottom: 0;
			}

			.prices {
				.price {
					font-size: 20px;
				}
			}

			.search-result-properties {
				margin-top: 5px;
				margin-bottom: 15px;
				min-height: 110px;

				.un-ft-bl {
					background-color: #f2f9ff;
					padding: 5px 10px;
					color: var(--primary);
					border: 1px solid var(--primary);
					margin-right: 5px;
					margin-bottom: 5px;
					display: inline-block;

					i {
						margin-right: 10px;
					}
				}

				@media (min-width: 992px) and (max-width: 1199px) {
					min-height: 190px;
				}
			}

			.mehr-btn {
				width: 100%;
				color: #fff;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				background: var(--primary);
				font-size: 18px;
				height: 40px;
				text-decoration: none;

				&:hover {
					background-color: var(--black);
				}
			}

		}

	}
}