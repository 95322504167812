@import "common.css";

.unit-view {


	.v-img-gallery {
		margin-top: 20px;

		a {
			display: inline-block;
			width: 25%;

			@media (max-width:767px) {
				width: 50%;
			}

			.img-responsive {
				width: 100%;
			}
		}
	}

	.unit-banner {
		position: relative;

		.page-header {
			position: absolute;
			bottom: 30px;
			width: 100%;
			left: 0;
			z-index: 2;
			margin: 0;
			border-bottom: none;

			h1 {
				font-size: 42px;
				margin-bottom: 0;
				color: var(--white);
				text-transform: capitalize;
			}

			.breadcrumb {
				padding: 30px 0;

				.breadcrumb-item {
					a {
						color: var(--white);
					}
				}
			}
		}

		&:after {
			background-color: rgba(0, 0, 0, .5);
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			content: "";
			z-index: 1;
		}
	}

	.side-price-bl {
		border: 2px solid var(--secondary);
	}

	.search-section {
		.searchbox {
			margin: 1px;
		}

		.search-popup-trigger {
			cursor: pointer;
		}
	}

	.title-img {

		position: relative;
		width: 100%;
		height: 320px;
		margin-bottom: 30px;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}



	/*.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}*/

	.page-title {
		h1 {
			font-size: 32px;
			margin-bottom: 0;
		}
	}

	.unit-list-section {
		margin-bottom: 30px;

		.owl-nav {
			position: absolute;
			top: 44%;
			width: 100%;

			@media (max-width:550px) {
				top: 30%;
			}

			button {
				color: #fff;
				text-shadow: 1px 1px 1px #000;
				position: absolute;
				z-index: 999;
				background: transparent;
				border: 0px;
				font-size: 70px;
				background: rgba(0, 0, 0, 0.4);

				span {
					padding: 0 10px;
					position: relative;
					top: -7px;
				}

				&.owl-next {
					right: 0;
				}
			}
		}
	}

	.main-section {
		position: relative;

		.legend-cal {
			text-align: center;
			padding: 0;
			margin: 15px 0;

			ul {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				padding: 0;
			}

			li {
				display: inline-flex;
				align-items: center;
				min-height: 30px;
				padding-right: 15px;
				margin: 5px 0;

				.day {
					width: 30px;
					height: 30px;
					background-color: #7e7975;
					display: inline-block;
					margin: 0 10px;
					border: 1px solid #e4e7e7;

					&.day-free {
						background: var(--white);
					}

					&.day-full {
						background: #42779e;
					}

					&.day-end {
						background: rgb(255, 255, 255);
						background: linear-gradient(90deg, rgba(255, 255, 255, 1) 70%, #42779e 80%);
					}

					&.day-start {
						background: rgb(205, 120, 152);
						background: linear-gradient(90deg, #42779e 20%, rgba(255, 255, 255, 1) 30%);
					}
				}
			}
		}


		/*.page-header {

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: var(--primary);
			}
		}*/

		.main-col {

			position: static;

			.stars {
				margin-top: 10px;
				color: var(--primary);
			}

			.description {
				padding: 0 0 15px;
			}

			.owl-item {
				img {
					height: 650px;
					object-fit: cover;
				}
			}

			/* .backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
				}

				>div {
					text-align: center;
				}
			}*/

			.properties {
				margin: 0 0 30px;
				@mixin columnlayout 4;
				padding: 0;

				li {
					list-style: none;
					font-size: 16px;
					position: relative;
					padding-left: 24px;
					margin-bottom: 5px;

					&:before {
						content: "\f058";
						color: var(--primary);
						position: absolute;
						left: 0;
						font-family: fontawesome;
						font-weight: normal;
					}
				}

				/*.prop.bool {
					font-weight: bold;
				}

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}*/
			}

			/*.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}
			}*/

			.feedbacks {
				blockquote {
					footer {
						padding: 10px 5px;
						margin-top: 15px;
					}
				}
			}

		}

		@media (min-width:768px) {
			.info-col.noaction {

				/*
				padding: 3px;
				padding-top: 10px;
				position: -webkit-sticky;
				position: sticky;
				top: 10px;
           */



				.info-col-main {
					border-bottom: none;
					width: 100%;
				}

				.subprice {
					width: 100%;
					border-top: none;
				}



			}
		}

		.fav-section {
			margin-top: 20px;

			.btn {
				border: 2px solid var(--primary);
				background-color: transparent;
				color: var(--primary) !important;
			}
		}

		.info-col-main {
			padding: 15px;
		}

		.backlink {
			padding: 0 0 10px;
		}


		.info-col {

			max-width: 800px;

			.info-col-header {
				padding: 15px;
				background-color: var(--secondary);

				h2 {
					margin: 0 !important;
					text-align: center;
					font-weight: bold;
					color: var(--white);
				}

			}

			.subrow {
				padding: 15px;

				.book-btn {
					font-size: 14px;
					padding: 6px 12px;
					height: auto;
					margin-top: 15px !important;
					margin-bottom: 0 !important;
				}
			}

			.subprice {

				.book-btn {
					margin-top: 30px;
					margin-bottom: 10px;
				}

				.flow-buttons {
					button {
						margin-left: 20px;
					}
				}
			}


			.info-col-header {


				h2 {
					margin-top: 10px;
					font-size: 24px;
				}
			}




		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}


	.subsection {
		width: 100%;

		.unit-map {
			height: 470px;
			width: 100%;



			@media (max-width:992px) {
				height: 520px;
			}

			@media (max-width:420px) {
				height: 560px;
			}

			.mapnote {
				font-size: 14px;
				padding: 20px 0;
				background: #fff;

			}

			>div {
				height: 350px;

				@media (max-width:991px) {
					height: 400px;
				}
			}

			&.no-map {
				height: auto;

				.mapconsent {
					height: auto;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					background: rgba(26, 121, 187, 0.15);
					flex-direction: column;
					padding: 15px;
					margin: 15px 0;

					.doc-box {}

					.mapconsent-button {
						background: var(--primary);
						color: #fff;
						border-radius: 5px;
						padding: 0 15px 0 15px;
						height: 36px;
						display: block;
						line-height: 35px;

						&:hover {
							background: var(--secondary);
						}
					}
				}

				@media (max-width:420px) {
					height: auto;
				}

				>div {
					height: auto;

					@media (max-width:991px) {
						height: auto;
					}
				}
			}
		}
	}


	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}



	@media all and (max-width:767px) {

		.title-img {
			height: 350px;

			/*.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}*/

		}

		/*.panorama {
			height: 350px;
		}*/


		.page-header h1 {
			font-size: 28px;
		}

		.main-section {

			.main-col {
				.properties {
					@mixin columnlayout 1;
				}
			}
		}
	}


	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		/*.panorama {
			height: 465px;
		}*/

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		/*.panorama {
			height: 580px;
		}*/

		/* .matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}*/


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}

}

@media all and (min-width: 320px) and (max-width: 480px) {
	.unit-view {
		.main-section {
			.main-col {
				.owl-item {
					img {
						height: 250px;
					}
				}
			}
		}
	}
}

@media all and (min-width: 481px) and (max-width: 640px) {
	.unit-view {
		.main-section {
			.main-col {
				.owl-item {
					img {
						height: 350px;
					}
				}
			}
		}
	}
}


.single-unit {
	margin-bottom: 20px;

	.unit-img {
		img {
			width: 100%;
		}
	}

	.unit-content {
		padding: 15px;
		background-color: var(--white);
		border: 1px solid #e2e2e2;

		a:hover {
			text-decoration: none;
		}

		.unit-title {
			font-size: 22px;
			line-height: 32px;
			color: var(--primary);
			font-weight: 700;
			padding-right: 25px;
		}

		.unit-rest-prp {
			margin-bottom: 20px;

			.un-ft-bl {
				background-color: #f2f9ff;
				padding: 5px 10px;
				color: var(--primary);
				border: 1px solid var(--primary);
				margin-right: 5px;
				margin-bottom: 5px;
				font-weight: 700;
				display: inline-block;

				i {
					margin-right: 10px;
				}
			}
		}
	}

}