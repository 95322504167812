footer {
	padding: 40px 0;
	background-color: rgba(238, 238, 238, .8);

	.widget-ft {

		@media (max-width:992px) {
			padding: 0 0 20px;
		}

		.weatherwidget-io {
			max-width: 230px;
		}

		.widget-title {
			margin-bottom: 20px;
			font-size: 24px;
			color: var(--black);
			text-transform: uppercase;
			font-weight: 600;
		}

		p {
			font-size: 16px;
		}

		.links {
			padding: 0;

			li {
				margin-bottom: 5px;

				list-style: none;

				.fa {
					margin-right: 10px;
					color: var(--primary);
				}

				a {
					color: var(--black);
				}
			}
		}

		.cont-bl {
			position: relative;
			padding-left: 30px;
			margin-bottom: 10px;

			.fa {
				position: absolute;
				left: 0;
				color: var(--primary);
				top: 3px;
			}
		}

		.social-bl {
			display: flex;
			flex-wrap: wrap;

			.icon-social {
				width: 30px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: var(--primary);
				color: var(--white);
				border-radius: 50%;
				margin-right: 10px;

				&:first-child {
					margin-left: 0;
				}

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

}

.dev-logo {
	display: inline-block;
}