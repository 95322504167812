#homenavbar {
	header {
		transition: all ease .25s;

		&.affix-top {
			top: 35px;
			background: rgba(255, 255, 255, .7) !important;
			box-shadow: inherit;
		}

	}

	header {
		position: fixed;
		z-index: 2;

		&.affix {
			top: 0;
			background: rgba(255, 255, 255, 1) !important;
			box-shadow: 0 2px 2px rgba(0, 0, 0, .1);
		}
	}
}

.vo-nav-bar {
	padding: 0;
	position: fixed;
	z-index: 5;
	width: 100%;
	background-color: var(--white);
	box-shadow: 0 2px 2px rgba(0, 0, 0, .1);
	top: 0;

	.container {
		position: relative;
	}

	.header-main {
		justify-content: space-between;
		width: 100%;

		@media (min-width:993px) {
			display: flex;
			align-items: center;
		}

		.logo-box {
			position: relative;
			top: 3px;
			display: inline-block;

			.logo {
				max-width: 110px;
				display: inline-block;

			}
		}


	}

	.navbar-side {
		display: flex;
		align-items: center;
	}

	.main-menu {
		align-items: center;
		display: flex;

		@media (max-width:992px) {
			display: none;
			position: absolute;
			right: 0;
			top: 100%;
			background: var(--white);
			max-height: 460px;
			overflow-x: scroll;
			width: 100%;
			box-shadow: 0 2px 6px rgba(0, 0, 0, .25)
		}

		ul {
			padding: 0;
			margin: 0;
		}

		li {
			list-style: none;
			display: inline-block;
			float: left;

			@media (max-width:992px) {
				display: block;
				float: none;
			}

			>a {
				display: block;
				color: var(--black);
				padding: 0 1rem;
				line-height: 45px;
				text-decoration: none;
				font-size: 16px;
				margin-left: .25rem;
				margin-right: .25rem;
				text-transform: uppercase;

				@media (max-width:992px) {
					font-size: 16px;
					line-height: 28px;
					padding: 5px 10px;
				}

				&:hover {
					color: var(--secondary);
				}

			}

		}
	}

	.nav-contact {
		display: flex;
		position: relative;
		top: -2px;

		@media (max-width:992px) {
			position: absolute;
			right: 40px;
			top: 17px;
		}

		a {
			margin-right: 10px;
			padding: 0;

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				.fa {
					background: var(--secondary);
				}
			}
		}

		.fa {
			font-size: 13px;
			width: 27px;
			height: 27px;
			background: var(--primary);
			color: var(--white);
			border-radius: 100%;
			text-align: center;
			line-height: 26px;

			@media (max-width:992px) {
				font-size: 14px;
				width: 29px;
				height: 29px;
				line-height: 28px;

			}

		}
	}

	&.opened {
		.main-menu {
			display: block;
		}
	}



	.icon {
		display: none;
	}

	.navicon {
		font-size: 20px;
	}

	/*.vo-dropdown,
	.input-group {
		float: left;
		overflow: hidden;
	}

	.input-group {
		padding: 9px 16px;

		.form-control {}
	}

	.vo-dropdown .dropbtn {
		font-size: 20px;
		border: none;
		outline: none;
		color: var(--black);
		padding: 0 1rem;
		line-height: 45px;
		background-color: inherit;
		font-family: inherit;
		margin-left: .75rem;
		margin-right: .75rem;

		&:hover {
			color: var(--primary);
		}

		@media (max-width:992px) {
			line-height: 28px;
			font-size: 16px;
			padding: 5px 10px;
		}

		.fa-caret-down {
			color: var(--primary);
			margin-left: 10px;
		}
	}

	.vo-dropdown-content {
		display: none;
		position: absolute;
		background-color: var(--primary);
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;
		padding: 20px;

		@media (max-width:992px) {
			border-radius: 0;
			padding: 10px 20px;
			position: relative;
		}

		.icon-arrow-up {
			width: 0px;
			height: 0px;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid var(--primary);
			content: "";
			transform: translate(90px, 0);
			position: absolute;
			top: -10px;

			@media (max-width:992px) {
				display: none;
			}
		}

		&.mega-menu {
			max-width: 580px;
			width: 100%;
		}
	}*/

	/*.vo-dropdown-content a {
		float: none;
		color: var(--white);
		padding: 8px 0;
		text-decoration: none;
		display: block;
		text-align: left;
		font-size: 16px;

		@media (max-width:992px) {
			padding: 5px 0;

		}
	}

	a:hover,
	.vo-dropdown:hover .dropbtn {}

	.vo-dropdown-content a:hover {
		color: var(--black);
	}


	.vo-dropdown:hover {
		color: red;
	}

	.vo-dropdown:hover .vo-dropdown-content {
		display: block;
	}*/


	@media screen and (max-width: var(--navbarbreak)) {
		a.icon {
			position: absolute;
			top: 15px;
			right: 15px;
			display: inline-block;
			color: var(--black);
			font-size: 20px;
		}


	}


	@media (min-width:var(--navbarbreak)) {
		/*.search {
			width: 150px;
			transition: width .3s ease-in-out;
			-webkit-transition: width .3s ease-in-out;
			-moz-transition: width .3s ease-in-out;
			-ms-transition: width .3s ease-in-out;
		}

		.search:focus-within {
			width: 250px;
		}

		.rightme {
			float: right;
		}
     
     */

	}



}