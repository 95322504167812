@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}


.map-pane {

	&.layout-column {
		@media (min-width:993px) {
			height: 580px;
		}

		@media (max-width:992px) {
			height: 520px;
			margin-top: 15px;
		}

		@media (max-width:420px) {
			height: 560px;
		}


		.no-map {
			.mapconsent {
				@media (min-width:993px) {
					height: 540px;
				}

				@media (max-width:992px) {
					height: 530px;
				}

				@media (max-width:420px) {
					height: 570px;
				}
			}


		}
	}



	.mapnote {
		padding: 0 0 20px;

		@media (max-width:992px) {
			padding: 15px 0;
		}

	}

	.map-main {
		>.flex {
			width: 100%;
			max-height: 510px;
			height: 100vh;

			@media (max-width:992px) {
				max-height: 457px;
			}

			>div {
				height: 100%;
			}
		}
	}


	.no-map {


		>.flex {
			width: 100%;
			max-height: 767px;
			height: 100vh;

			>div {
				height: 100%;

				.mapconsent {

					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					background: rgba(26, 121, 187, 0.15);
					flex-direction: column;
					padding: 0 15px;


					.mapconsent-button {
						background: var(--primary);
						color: #fff;
						border-radius: 5px;
						padding: 0 15px 0 15px;
						height: 36px;
						display: block;
						line-height: 35px;

						&:hover {
							background: var(--secondary);
						}
					}
				}
			}
		}

	}

}

.vo-search-body {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	.container-fluid {
		/*max-width: 1440px;
		width: 100%;*/
	}

	.unit-price {
		display: inline-block;
		text-align: left;

		span {
			font-size: 16px;

			strong {
				font-size: 18px;
				font-weight: 700;
				color: var(--primary);
			}

			&.main-price {
				text-decoration: line-through;
				float: left;
				margin-right: 6px;
			}

			&.discount-price {
				font-size: 20px;
				position: relative;
				top: -2px;

				strong {
					color: red;
				}
			}
		}
	}

	.searchBanner {
		background-image: url(RESOURCE/img/search-banner-new5.jpg);
		background-position: 50% 30%;
		background-repeat: no-repeat;
		background-size: cover;
		padding: 65px 0 0;
		min-height: 320px;
		display: flex;
		align-items: center;

		.show-map-list {
			float: right;

			a {
				display: inline-block;
				background: #ffffff;
				border: solid 2px #ffffff;
				padding: 10px 45px;
				text-transform: uppercase;
				font-size: 18px;
				color: #215d90;
				border-radius: 0px;
				margin: 8px 0px;
				text-decoration: none
			}
		}

		.form-control {
			background: rgba(255, 255, 255, 0.9);
			border-radius: 0px;
			padding: 10px 50px 10px 15px;
			border: solid 1px var(--primary);
			font-size: 16px;
			line-height: 24px;
			color: #215d90;
			margin: 8px 0px;
			position: relative;
			height: 44px;

			&.datePickerField,
			&.guestField,
			&.regionField,
			&.bedroomField {
				background: rgba(255, 255, 255, 0.9) url(RESOURCE/img/dropdown-icon.png) no-repeat center right 15px;
				padding: 10px 50px 10px 15px;
			}
		}


		select.form-control {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			position: relative;
			color: #215d90;
			background: rgba(255, 255, 255, 0.9) url(RESOURCE/img/dropdown-icon.png) no-repeat center right 15px;
		}

		.searchBtn {
			.btn {
				display: inline-block;
				border: solid 1.5px #ffffff;
				padding: 10px 60px;
				text-transform: uppercase;
				font-size: 18px;
				color: #ffffff;
				border-radius: 0px;
				margin: 8px 0px;
			}
		}

		.adv-filt-form {
			background: rgba(255, 255, 255, 0.8);
			padding: 15px 20px;
			margin-top: 10px;

			.form-control {
				border: 1px solid #215d90;
				padding: 10px 15px;
				font-size: 15px;
				color: #215d90;
				border-radius: 0px;
				line-height: 24px;
				margin: 8px 0px;
				height: auto;
			}

			.form-group {
				margin: 0px;

				.checkbox,
				.radio {
					margin-top: 5px;
					margin-bottom: 5px;
				}
			}
		}




		@media screen and (max-width: 767px) {
			.asd__wrapper--full-screen {
				top: 120px;
				overflow: auto;
				z-index: 12345;
			}
		}

		@media screen and (max-width: 639px) {
			padding: 150px 10px 45px 10px;

			.col-xs-6,
			.col-xs-12 {
				padding: 0px 8px;
			}

			.form-control {
				font-size: 15px;
			}

			.searchBtn {
				.btn {
					width: 100%;
					font-size: 16px;
				}
			}

			.show-map-list {
				width: 100%;
				text-align: center;

				a {
					width: 100%;
					font-size: 16px;
				}
			}
		}

		@media screen and (max-width: 639px) {

			.col-xs-6,
			.col-xs-12 {
				width: 100%;
			}
		}

		@media screen and (max-width: 480px) {
			.box.full-xxs {
				top: 120px !important;
			}
		}
	}

	.chk-tr-bl {
		background: rgba(255, 255, 255, .9);
		padding: 10px 50px 10px 15px;
		border: solid 1px var(--primary);
		margin: 8px 0;
	}




	.results.resultLists {
		background: #ffffff;

		.srow {
			margin-top: 15px;
			margin-bottom: 0;

			a {
				text-decoration: none;
			}

			.info-col {
				padding: 15px;
				border: solid 1px #e2e2e2;
				border-left: none;

				.map-marker {
					.fas {
						color: var(--secondary);
					}
				}

				h4 {
					font-size: 22px;
					line-height: 32px;
					color: #215d90;
					font-weight: 700;
					padding-right: 25px;
				}

				h6 {
					font-size: 16px;
					line-height: 24px;
					margin: 0px 0px 15px 0px;
				}

				.alternativesPrice {
					float: left;
					width: 100%;
					margin: 0px 0px 15px 0px;
					font-size: 15px;
					line-height: 22px;

					h5 {
						margin: 0px 0px 10px 0px;
					}

					a {
						color: #215d90;
						text-decoration: underline;

						&:hover {
							text-decoration: none;
						}
					}
				}

				.rentRate {
					float: left;
					width: 100%;
					font-size: 15px;
					color: #222222;

					strong {
						font-size: 18px;

					}
				}

				.search-result-properties {
					width: 100%;
					margin: 0px 0px 15px 0px;
					padding: 0px 0px;

					.propertiesList {
						float: left;
						font-size: 15px;
						padding: 0px 15px;

						&:first-child {
							padding-left: 0px;
						}

						div {
							float: left;
						}

						.fas {
							margin-right: 8px;
							font-size: 18px;
							color: #215d90;
						}
					}
				}
			}
		}

		@media screen and (max-width: 479px) {
			.srow {
				.info-col {
					h4 {
						font-size: 18px;
						font-size: 28px;
					}
				}
			}
		}
	}

	.list-view {
		padding-top: 20px;
		padding-bottom: 20px;

		.map-col {
			.affix {
				@media (min-width:993px) {
					position: fixed !important;
					top: 40px;
					max-width: 390px;
				}
			}
		}
	}

	.map-view {
		.map-col {
			margin-top: 20px;
			margin-bottom: 20px;

			.affix,
			.affix-bottom {
				@media (min-width:993px) {
					position: relative !important;
					top: 0 !important;
					width: 100%;
					padding-right: 0;
					max-width: inherit;
				}
			}
		}
	}


	.map-col {
		.affix {
			@media (max-width:992px) {
				position: relative !important;
			}

			@media (min-width:993px) {
				width: 33.33%;
				max-width: inherit;
				padding-right: 30px;
				top: 60px;
			}

			@media (min-width:1440px) {
				position: fixed !important;
				top: 50px;
				width: 100%;
				max-width: 450px;
			}

		}

		.affix-bottom {
			@media (max-width:992px) {
				position: relative;
				top: inherit !important;
			}
		}
	}

	.result-pane {
		width: 100%;
		padding: 0px;
		color: #565a5c;

		.text-truncate {
			a {
				color: var(--primary);
			}
		}



		.a-color {
			color: #007da7;
		}

		.result-header {
			padding: 0px 0px;
			border-bottom: 1px solid rgb(235, 235, 235);
			margin-bottom: 15px;

			.sorting-trigger {
				cursor: pointer;
			}

			.sorting-area {
				margin-bottom: 15px;
				display: block;

				strong {
					@media (max-width:450px) {
						display: block;
					}
				}
			}

			.price-drop {
				position: relative;
				display: inline-block;

				.dropdown-menu {
					border-radius: 0;
					border-color: var(--secondary);

					a {
						display: block !important;
					}
				}
			}

			.sort-item {
				padding: 8px 15px;
				font-size: 16px;
				background-color: var(--secondary);
				cursor: pointer;
				display: inline-block;
				color: var(--white);

				&:hover {
					text-decoration: none;
				}

				&.sort-th {
					&:before {
						content: "\f00a";
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						margin-right: 7px;
					}
				}

				&.sort-price {
					&:before {
						content: "\f02b";
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						margin-right: 7px;
					}
				}

				&.sort-name {
					&:before {
						content: "\f15d";
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						margin-right: 7px;
					}
				}
			}

			.mysearch {
				margin-bottom: 4px;
			}
		}

		.pagination-panel {
			text-align: center;

			li {

				a,
				span {
					border-radius: 0px !important;
				}
			}

			.active {
				a {
					background: #215d90;
					color: #ffffff;
					border: solid 1px #215d90;
					font-weight: 600;
				}
			}
		}

	}

	.map-view {
		.result-pane {
			@media (min-width:993px) {
				display: none;
			}
		}

		.list-content {
			@media (min-width:993px) {
				display: none;
			}
		}

		.map-col {
			width: 100%;
		}
	}

	.map-pane {
		padding: 0;

		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		a {
			color: inherit;
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;
		}

		.preview-img {
			min-height: 150px;

			img {
				max-width: 225px;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}


	.results,
	.spinner-panel,
	.more-btn-panel {
		background-color: #f5f5f5;
	}

	.more-btn-panel {
		padding-bottom: 40px;
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.results {

		padding: 0;

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
		}

		.altheading {
			color: red;
		}

		h4 {
			margin-top: 0;
		}

		.srow {
			width: 100%;
			position: relative;

			padding: 5px;
			padding-bottom: 10px;

			.map-marker {
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 50px;
				text-align: center;

				.fa-map-marker-alt {
					position: relative;
					top: 15px;
					cursor: pointer;
					font-size: 24px;
					color: #dedede;
				}

				.fa-map-marker-alt:hover,
				.fa-map-marker-alt.selected {
					color: #565a5c;
				}
			}

			.img-col {

				.img-content {

					.full-img {
						width: 100%;
						height: auto;
					}


					.fav-icon {
						position: absolute;
						cursor: pointer;
						right: 3px;
						top: 5px;
						color: #fff;
						margin: 3px;
						font-size: 24px;
						width: 35px;
						text-align: center;
					}


				}


			}

			.info-col {
				position: relative;
				padding: 3px 3px 3px 6px;
				background-color: white;
				box-shadow: 0 1px 3px rgba(0, 0, 0, .1);

				.stars {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.prices {
					.price {
						font-size: 20px;
					}
				}

				.search-result-properties {
					margin-top: 5px;
					margin-bottom: 15px;

					.un-ft-bl {
						background-color: #f2f9ff;
						padding: 5px 10px;
						color: var(--primary);
						border: 1px solid var(--primary);
						margin-right: 5px;
						margin-bottom: 5px;

						i {
							margin-right: 10px;
						}
					}
				}


			}
		}


	}


	.search-result-properties {
		display: inline-block;

		>div {
			display: inline-block;
			margin: 5px;
			text-align: center;
			padding: 3px;
			color: var(--primary);
			border: 1px solid var(--primary);

			i {
				margin-right: 5px;
			}

			:last-child {
				font-weight: bold;
			}
		}

	}



	.search-bar {
		padding: 5px 10px;

		.daterange {
			display: inline-block;
		}

		.btn {
			margin: 2px;
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}
		}
	}

	@media (max-width:767px) {

		.list-view {
			.map-pane {
				display: none;
			}
		}

		.map-view {
			.map-pane {
				@mixin flex;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 80px !important;
			height: auto;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: none;
		}

	}

	.map-pane {
		@mixin flex;


		@media (max-width:992px) {
			z-index: 50;
			padding: 0;
		}
	}

	@media (min-width:768px) {
		.map-pane {
			@mixin flex;

		}

		.result-pane {


			.more-btn-panel {
				display: none;
			}


		}


		.results {
			.srow {
				@mixin flexlayout row;

				.info-col {
					width: 60%;
				}

				.img-col {
					@mixin flex;
					position: relative;

					.img-content {
						position: absolute;
						@mixin size100;


						.full-img {
							height: 100%;
							object-fit: cover;

						}
					}

				}

			}
		}



	}





}