.booking-view {

	margin-top: 50px;

	.checkbox {
		margin-bottom: 10px;
	}

	.cart-details {
		padding-top: 60px;

		.unit-details {
			margin-top: 10px;
			padding-left: 20px;

			li {
				margin-left: 0px;
			}
		}

		.service-title {
			button {
				display: none;
			}
		}
	}

	.panel-default {
		.input-group {
			@media (max-width: 767px) {
				display: block;
			}

			.form-control {
				@media (max-width: 767px) {
					width: 100%;
				}
			}

			.btn-success {
				background: var(--primary);
				border: 1px solid var(--primary);

				@media (max-width: 767px) {
					width: 100%;
					margin: 15px 0 0 0;
				}

				&:hover {
					background: var(--black);
					border: 1px solid var(--black);
				}
			}
		}
	}

	.pd-form {
		label {
			font-weight: normal;

			a {
				font-weight: normal;
			}
		}

		label.lrequired {
			font-weight: 700;
		}

	}

	.final-col {
		.checks {
			margin-bottom: 20px;

			label {
				font-weight: 700;

				a {
					font-weight: normal;
				}
			}
		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.flow-btn {
		margin-left: 20px;
		margin-bottom: 20px;
	}


	.pay-online-box {
		min-height: 240px;

		.amount-options-list {
			padding-left: 10px;
		}

		.amount-option-btn {
			list-style-type: none;
		}

		.amount-option {
			border: none;
			font-weight: bold;
			padding: 0;
			margin-bottom: 4px;
			margin-left: 10px;
		}

		.amount-or {
			margin-top: 2px;
			font-weight: normal;
			font-style: italic;
		}

		.bank-detail-box {
			padding-left: 10px;
		}
	}


}