@import "setup.css";

.indexpage {

	.banner-slide {
		position: absolute;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: 0;
		min-height: 700px;

		.owl-carousel,
		.owl-stage-outer,
		.owl-stage,
		.owl-item {
			height: 100%;
		}

		@media (max-width:1199px) {
			min-height: 570px;

			.owl-carousel,
			.owl-stage-outer,
			.owl-stage,
			.owl-item {
				height: 100%;
			}
		}


		.item {
			-webkit-background-size: cover;
			background-size: cover;
			background-repeat: no-repeat;
			position: relative;
			//height: 100vh;
			min-height: 700px;
			height: 100%;
			background-position: center;

			/*&:before {
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba(255, 255, 255, .2);
				z-index: 2;
			}*/

			@media (max-width:1199px) {
				min-height: 570px;
				height: 100%;
			}

			&.slide0 {
				background-image: url(RESOURCE/img/banner-home-4.jpeg);
			}

			&.slide1 {
				background-image: var(--introImg);
			}

			&.slide2 {
				background-image: url(RESOURCE/img/bg-banner-home2.jpg);
			}
		}
	}

	.banner-section {
		position: relative;
		min-height: 570px;
		height: 100vh;

		.jumbotron {
			padding-top: 30vh;
			text-align: center;

			@media (max-height:580px) {
				padding-top: 20vh;
			}
		}

		.container-fluid {
			padding: 0;
		}
	}

	.owl-nav {
		button {
			font-size: 65px !important;
			color: var(--black) !important;

			&.owl-prev {
				position: absolute;
				top: 40%;
				left: 15px;
			}

			&.owl-next {
				position: absolute;
				top: 40%;
				font-size: 65px;
				right: 15px;
				color: var(--white);
			}
		}

	}

	.intro {
		text-align: center;
		padding-top: 20vh;

		.logo-img {
			img {
				display: inline-block;
			}
		}

		.hero {
			text-align: center;
			background: none;
			left: 50%;
			top: 0;
			position: absolute;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			margin-bottom: 10px;
			padding-bottom: 0;

			.logo-background {
				background-color: rgba(255, 255, 255, 0.7);
				border-radius: 50%;
				display: inline-block;
				padding: 15%;

			}

			h1 {
				font-weight: 700;
				margin: 60px 0 0;

				color: var(--primary);
				display: block;

				font-size: 70px;
				text-transform: uppercase;

				@media (max-width:767px) {
					font-size: 42px;
				}
			}

			h2 {
				font-size: 30px;
				border-top: 1px solid #000;
				padding-top: 10px;
				display: inline-block;
				color: var(--black);
				font-weight: 400;
				margin: 20px 0 0;
				letter-spacing: 1px;

			}

		}

	}

	/*.banner-slide {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;

		.owl-dots {
			display: none;
		}

		.item {
			height: 100vh;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			display: flex;
			align-items: center;
			padding: 0 15px;
			width: 100%;
			justify-content: center;
			min-height: 500px;

			@media (min-width:1200px) {
				min-height: 700px;
			}

			&.slide1 {
				background-image: url(RESOURCE/img/banner-slide1-new.jpg);
			}

			&.slide2 {
				background-image: url(RESOURCE/img/banner-slide2-new.jpg);
			}

			&.slide3 {
				background-image: url(RESOURCE/img/banner-slide3-new.jpg);
			}

		}
	}*/



	.searchbar {
		width: 100%;
		bottom: 4vh;
		position: absolute;
		background: rgba(255, 255, 255, 0.4);

		@media (max-width:992px) {
			bottom: 6vh;
		}


		.search-form-home {
			.searchbox {
				@media (max-width:767px) {
					padding: 0;

				}
			}
		}


		.row {
			margin: 0 !important;
			padding: 10px;
		}

		.searchbox {
			padding: 5px;

			input,
			.search-btn,
			select {
				border-radius: 0;
			}

			.search-btn {
				width: 100%;
			}

			.search-popup-trigger {
				cursor: pointer;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: white;
			}
		}

	}

	/*.contact-section {
		background-color: #fff !important;
	}*/






	/*@media (max-width:767px) {

		.iheader {
			background: var(--introMobileImg) no-repeat center;
			-webkit-background-size: cover;
			background-size: cover;
			height: 100vh;

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}


		}
	}*/






}