@import "common.css";

.inner-banner {
	/*background-image: url(RESOURCE/img/bg-banner-immobilin-new.jpg);*/
	background-image: url(RESOURCE/img/search-banner-new5.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 30%;
	width: 100%;
	height: 550px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	@media (max-width:767.98px) {
		height: 375px;
	}

	.pagetitle {
		position: absolute;
		bottom: 20px;
		text-align: right;
	}

	.inner-pag-title {
		display: block;
		text-align: center;
		color: var(--white);
		margin-top: 45px;
		position: relative;
		z-index: 2;
		text-shadow: 2px 2px 2px #7caed3;
	}

	/*
	&:before {
		content: "";
		position: absolute;
		top: 0;
		opacity: .7;
		left: 0;
		background-color: #2e4852;
		width: 100%;
		height: 100%;
	} */

}

.inner-banner2 {
	/*background-image: url(RESOURCE/img/bg-banner-immobilin-new.jpg);*/
	background-image: url(RESOURCE/img/bg-banner-home2.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 30%;
	width: 100%;
	height: 550px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	@media (max-width:767.98px) {
		height: 375px;
	}

	.pagetitle {
		position: absolute;
		bottom: 20px;
		text-align: right;
	}

	.inner-pag-title {
		display: block;
		text-align: center;
		color: var(--white);
		margin-top: 45px;
		position: relative;
		z-index: 2;
		text-shadow: 2px 2px 2px #7caed3;
	}

	/*
	&:before {
		content: "";
		position: absolute;
		top: 0;
		opacity: .7;
		left: 0;
		background-color: #2e4852;
		width: 100%;
		height: 100%;
	} */

}

.teams-bl {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;

	.user-name {
		color: var(--primary);
		font-weight: bold;
		margin-top: 15px;
	}
}

.ct-form-section {
	.mapnote {
		margin: 0 0 15px 0;
		font-size: 14px;
	}

	.mapconsent {
		background: rgba(26, 121, 187, .15);
		padding: 15px;

		a {
			background: var(--primary);
			color: #fff;
			border-radius: 5px;
			padding: 0 15px;
			height: 36px;
			display: inline-block;
			line-height: 35px;
			margin: 0 0 15px 0;

			&:hover {
				background: var(--secondary);
			}
		}
	}
}


.big-para {
	font-size: 16px;

	p {
		font-size: 16px;
	}
}

.bg-li-blue {
	background-color: #f2f9ff;
}

.blog,
.blogentry {
	&.container {
		padding-bottom: 60px;
	}

	h1 {
		font-weight: 600;
		color: var(--primary);
	}

	.entries {
		.blog-entry {
			.full-img {
				width: 100%;
			}

			.blog-header {
				color: var(--secondary);
				font-weight: bold;
				font-size: 16px;
			}

			h2 {
				font-weight: bold;
				color: var(--primary);
			}
		}
	}

	.blogcontent {
		.img-thumbnail {
			border: none;
			padding: 0;
			border-radius: 0;
		}
	}


}

.Impressum-main {
	h4 {
		@media (max-width: 767px) {
			word-break: break-word;
		}
	}
}